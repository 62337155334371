* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Orbitron', 'Arial', sans-serif;
  }
  
  .newsletter-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 20px;
    text-align: center;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .logo {
    width: 150px;
    margin-bottom: 15px;
  }
  
  .company-name {
    color: #2c3e50;
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .welcome-message {
    color: #34495e;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .datetime {
    background-color: #2c3e50;
    color: #00ff00;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 250px;
  }
  
  .datetime p {
    font-size: 1.5rem;
    letter-spacing: 2px;
    margin: 5px 0;
  }
  
  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
    max-width: 300px;
  }
  
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px;
    background-color: #3498db;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .btn svg {
    margin-right: 10px;
  }
  
  .btn:hover {
    background-color: #2980b9;
    transform: scale(1.05);
  }
  
  @media (max-width: 480px) {
    .company-name {
      font-size: 1.5rem;
    }
  
    .welcome-message {
      font-size: 1rem;
    }
  
    .datetime {
      min-width: 200px;
    }
  
    .datetime p {
      font-size: 1.2rem;
    }
  }