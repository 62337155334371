/* src/App.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  .logo {
    max-width: 100%; /* Adjust to fit the container */
    height: auto;
    margin-bottom: 10px;
  }
  